<template>
  <div class="content">
    <Hero
      :image="getStrapiImageLocation(data?.hero?.image) || ''"
      image-provider="strapi"
      extra-content-classes="grid--two"
      :banner="event?.banner"
      :banner-link="event?.slug"
    >
      <template #left>
        <h1 class="heading-1 text--white" id="home-heading" v-html="addBreakToBold(data?.hero?.heading || '')"></h1>
        <h4 class="heading-4 text--xxl text--white mb-1">{{ data?.hero?.leftSubHeading }}</h4>
        <div class="text--l text--white">
          <StrapiBlocksText :nodes="data?.hero?.leftText || []"/>
        </div>
      </template>
      <template #right>
        <h5 class="heading-5 text--white" style="text-wrap: balance;">{{ data?.hero?.rightHeading }}</h5>
        <p class="text--white text--l" style="text-wrap: balance;">{{ data?.hero?.rightText }}</p>
        <!-- <p class="text--white text--l" v-html="data?.hero?.rightAboveButtonText"></p> -->
        <nuxt-link :href="data?.hero?.rightButton?.link" class="btn btn--white">{{ data?.hero?.rightButton?.content }}</nuxt-link>
      </template>
    </Hero>

    <main class="main">
      <div class="block block--y maxw--inner" id="vacatures">
        <h2 class="heading-2 text--primary text--center mb-2 text--cramped">{{ data?.vacancies?.heading }}</h2>
        <div class="grid--three grid--bigger-row">
          <VacancyItem v-for="item in vacancies" :key="item.id" :data="item" :button-text="data?.vacancies?.buttonText"/>
        </div>
      </div>
      <div class="block block--y maxw--inner" id="intro_video" v-if="data?.video">
        <div class="grid--video">
          <h3 class="heading-2 bigger text--vaneerd text--secondary">{{ data?.video?.heading }}</h3>
          <VideoPlayer class="video--intro" :source="getStrapiImageLocation(data?.video?.video, true) || ''" :poster="getStrapiImageLocation(data?.video?.previewImage, true, false)"/>
        </div>
      </div>
      <div class="block block--accent block--yl" v-if="event?.banner">
        <div class="maxw--inner grid--two">
          <div>
            <h2 class="heading-1 text--white" v-html="addBreakToBold(event?.banner?.bigBannerHeading || '')"></h2>
          </div>
          <div>
            <p class="text--l text--white mb-2" style="max-width: 344px;">{{ event?.banner?.bigBannerText }}</p>
            <nuxt-link :to="`/evenementen/${event?.slug}`" class="btn btn--white text--accent">Meld je aan</nuxt-link>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="block block--secondary maxw--inner block--p-sm" id="open_vacancy">
          <div class="image--edged image--holder image--left">
            <NuxtImg :src="getStrapiImageLocation(data?.openVacancy?.image) || ''" provider="strapi"/>
          </div>
          <div class="block__column">
            <h4 class="heading--med text--white text--vaneerd">{{data?.openVacancy?.supheading}}</h4>
            <h4 class="heading--med mb-2 text--white text--bold" v-html="data?.openVacancy?.heading || ''"></h4>
            <h6 class="text--white text--l">{{ data?.openVacancy?.subheading }}</h6>
          </div>
          <div class="block__column text--white">
            <span class="list__heading text--m">{{ data?.openVacancy?.rightHeading }}</span>
            <ul class="list list--dots mb-2">
              <li 
                class="list__item text--m" 
                v-for="point in data?.openVacancy?.rightBulletPoints || []"
                :key="point.id"
              >
                {{ point.value }}  
              </li>
            </ul>
            <nuxt-link :to="data?.openVacancy?.buttonRight?.link" class="btn btn--white text--secondary">{{ data?.openVacancy?.buttonRight?.content }}</nuxt-link>
          </div>
        </div>
      </div>
      <div class="block block--gray">
        <div class="maxw--inner grid-text-img">
          <div class="block__column mb-2">
            <h2 class="heading--med text--primary mb-2">{{ story?.preTitle }}<br v-if="story?.preTitle"/><b>{{ story?.title }}</b></h2>
            <div class="text--l mb-2 last-nm">
              <StrapiBlocksText :nodes="story?.previewText || []"/>
            </div>
            <nuxt-link :to="`/ervaringsverhalen/${story?.slug}`" class="btn btn--secondary mb-2">Lees meer</nuxt-link>
            <nuxt-link to="/ervaringsverhalen" class="text--primary"><i>Bekijk alle ervaringsverhalen</i></nuxt-link>
          </div>
          <div>
            <div class="image--holder">
              <NuxtImg :src="getStrapiImageLocation(story?.thumbnail) || ''" :alt="story?.title" provider="strapi"/>
            </div>
          </div>
        </div>
      </div>
      <div class="block block--top-gray block--bottom-secondary block--ynone">
        <div class="block block--white maxw--inner block--px-l">
          <h4 class="heading--med text--center text--secondary text--vaneerd">{{data?.middleCTASection?.subheading}}</h4>
          <h3 class="heading-2 text--center text--secondary mb-2 bigger">{{ data?.middleCTASection?.heading }}</h3>
          <div class="text--l mb-2 text--center">
            <StrapiBlocksText :nodes="data?.middleCTASection?.alineaOne || []"/>
            <!-- <NuxtImg class="avatar avatar--xl float--right avater--border-secondary" src="/images/linda_nobg.png" alt="Avatar"/> -->
            <StrapiBlocksText :nodes="data?.middleCTASection?.alineaTwo || []"/>
          </div>
          <div class="centered">
            <nuxt-link :to="data?.middleCTASection?.button?.link" class="btn btn--secondary">{{ data?.middleCTASection?.button?.content }}</nuxt-link>
          </div>
        </div>
      </div>
      <div class="block block--secondary" id="5-stappen">
        <h2 class="heading-1 text--white text--center mb-2" v-html="addBreakToBold(data?.fiveSteps?.heading || '')"></h2>
        <div class="grid--five maxw--inner">
          <StepItem 
            v-for="item, i in data?.fiveSteps?.steps || []" 
            :key="i"
            :icon="getStrapiImageLocation(item?.image) || ''"
            :title="item.heading"
            :text="item.text"
          />
        </div>
      </div>
    </main>
  </div>
  <div class="block maxw--inner block--y-all block--bnone">
    <Hero
      id="over"
      :image="getStrapiImageLocation(data?.about?.image) || ''"
      image-provider="strapi"
      extra-content-classes="vcenter grid--two"
      :vertical-center="true"
      small
    >
      <template #left>
        <h2 class="heading--med text--white" v-html="addBreakToBold(data?.about?.heading || '')"></h2>
      </template>
    </Hero>
  </div>
  <div class="block">
    <div class="grid--two maxw--inner grid--small-gap text--l">
      <div class="text--l last-nm">
        <StrapiBlocksText :nodes="data?.aboutColumnLeft || []"/>
      </div>
      <div class="text--l last-nm">
        <StrapiBlocksText :nodes="data?.aboutColumnRight || []"/>
      </div>
    </div>
  </div>
  <div class="block block--xnone block--ynone maxw--inner">
    <div class="block block--secondary maxw--inner block--p-sm grid--image-left">
      <div class="image--edged image--holder image--left">
        <NuxtImg :src="getStrapiImageLocation(data?.bottomBlock?.image) || ''" style="object-position: 80% top;" provider="strapi"/>
      </div>
      <div class="block__column">
        <h4 class="heading--med text--white text--vaneerd">{{data?.bottomBlock?.subheading}}</h4>
        <h4 class="heading--med mb-2 text--white text--bold">{{ data?.bottomBlock?.heading }}</h4>
        <span class="text--white text--l mb-2">{{ data?.bottomBlock?.text }}</span>
        <nuxt-link :href="data?.bottomBlock?.button?.link" class="btn btn--white">{{ data?.bottomBlock?.button?.content }}</nuxt-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type HomePage from '@/typings/strapi/pages/homePage';
import type EventPage from '~/typings/strapi/pages/eventPage';
import type StoryPage from '~/typings/strapi/pages/storyPage';
import type VacancyPage from '~/typings/strapi/pages/vacancyPage';

const { findOne, find } = useStrapi();

const { data } = await useAsyncData(
  'homePage',
  () => findOne<HomePage>('home-page', { 
    populate: [
      'hero', 'hero.rightButton', 'hero.image',
      'vacancies',
      'video', 'video.previewImage', 'video.video',
      'openVacancy', 'openVacancy.buttonRight', 'openVacancy.image', 'openVacancy.rightBulletPoints',
      'middleCTASection', 'middleCTASection.avatar', 'middleCTASection.button',
      'about', 'about.image',
      'bottomBlock', 'bottomBlock.image', 'bottomBlock.button',
      'fiveSteps', 'fiveSteps.steps', 'fiveSteps.steps.image',
      'seo', 'seo.metaImage'
    ] 
  }),
  {
    transform: flattenObject
  }
);

const { data: stories } = await useAsyncData(
  'stories',
  () => find<StoryPage>('story-pages', { 
    pagination: { limit: 1, start: 0},
    sort: 'publishedAt:desc',
    populate: ['thumbnail']
  }),
  {
    transform: flattenCollection
  }
);

const story = computed<StoryPage|null>(() => stories.value !== null && stories.value.length > 0 ? stories.value[0] : null);

const { data: vacancies } = await useAsyncData(
  'vacancies',
  () => find<VacancyPage>('vacancy-pages', {
    populate: ['image'],
    sort: 'rank:asc',
  }),
  {
    transform: flattenCollection
  }
);

const { data: events } = await useAsyncData(
  'events',
  () => find<EventPage>('event-pages', {
    populate: ['banner'],
    sort: 'publishedAt:desc',
    // This incorperated the filter for the event date, but it's replaced
    // with the simple functionality of using draft/published versioning
    // filters: {
    //   $and: [
    //     { showFrom: { $lte: new Date().toISOString() } },
    //     { showTill: { $gt: new Date().toISOString() } }
    //   ]
    // }
  }),
  {
    transform: flattenCollection,
    lazy: true
  }
);

const event = computed<EventPage|null>(() => events.value !== null && events.value.length > 0 ? events.value[0] : null);

useHead({
  title: 'Werken bij Van Eerd',
  titleTemplate: '%s'
});

useSeoMeta({
  description: data.value?.seo?.metaDescription,
  ogTitle: data.value?.seo?.metaTitle,
  ogDescription: data.value?.seo?.metaDescription,
  ogImage: getStrapiImageLocation(data.value?.seo?.metaImage, true),
  ogUrl: 'https://werkenbijvaneerd.nl/',
  twitterTitle: data.value?.seo?.metaTitle,
  twitterDescription: data.value?.seo?.metaDescription,
  twitterImage: getStrapiImageLocation(data.value?.seo?.metaImage, true),
  twitterCard: 'summary_large_image',
})
</script>
