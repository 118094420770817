import { default as contactfzwMGjpTgRMeta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/contact.vue?macro=true";
import { default as _91slug_93QlFEMTsW5NMeta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/ervaringsverhalen/[slug].vue?macro=true";
import { default as indexVDKdhcX5BZMeta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/ervaringsverhalen/index.vue?macro=true";
import { default as _91slug_93YTSp5SGlM8Meta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/evenementen/[slug].vue?macro=true";
import { default as indexCCFjsum126Meta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/index.vue?macro=true";
import { default as privacy_45beleidq8X1gjOrIkMeta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/privacy-beleid.vue?macro=true";
import { default as _91slug_93d9wpa8zTQqMeta } from "/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/vacatures/[slug].vue?macro=true";
export default [
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/contact.vue")
  },
  {
    name: "ervaringsverhalen-slug",
    path: "/ervaringsverhalen/:slug()",
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/ervaringsverhalen/[slug].vue")
  },
  {
    name: "ervaringsverhalen",
    path: "/ervaringsverhalen",
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/ervaringsverhalen/index.vue")
  },
  {
    name: "evenementen-slug",
    path: "/evenementen/:slug()",
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/evenementen/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/index.vue")
  },
  {
    name: "privacy-beleid",
    path: "/privacy-beleid",
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/privacy-beleid.vue")
  },
  {
    name: "vacatures-slug",
    path: "/vacatures/:slug()",
    component: () => import("/home/runner/work/vaneerd-werkenbij/vaneerd-werkenbij/website/pages/vacatures/[slug].vue")
  }
]